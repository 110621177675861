import React from 'react'
import "./App.css"
import { MainRoutes } from './routing'

const App = () => {
  return (
    <div>
      <MainRoutes />
    </div>
  )
}

export default App

import React, { useState, useEffect } from 'react'
import "./form.css"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { db } from "../../firebase"
import { uid } from "uid"
import { set, ref } from "firebase/database"

const Form = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [article, setArticle] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    setAlertOpen(false);
  }, [])

  useEffect(() => {
    if (alertOpen) {
      const timeoutId = setTimeout(() => {
        setAlertOpen(false);
      }, 10000);

      return () => clearTimeout(timeoutId);
    }
  }, [alertOpen]);


  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const writeToDatabase = () => {
    if (!title || !description || !article) {
      setAlertType("error");
      setAlertOpen(true);
      return;
    }

    const uuid = uid()
    set(ref(db, `/${uuid}`), {
        title,
        description,
        article,
        uuid,
    })

    .then(() => {
      setTitle("");
      setDescription("");
      setArticle("");
      setAlertType("success");
      setAlertOpen(true);
    })
    .catch((error) => {
      setAlertType("error");
      setAlertOpen(true);
    });
  };

  const handleAlertClose = () => {
    setTimeout(() => {
      setAlertOpen(false);
    }, 5000);
  };  
  
  return ( 
    <div className='admindashboard__form'>
        <div className='admindashboard__form-title'>
            <input required type="text" placeholder="Title" value={title} onChange={handleTitleChange} />
            <input required type="text" placeholder="Meta Description" value={description} onChange={handleDescriptionChange} />
        </div>
        <div className='admindashboard__form-article'>
          <CKEditor
            editor={ClassicEditor}
            data={article}
            onChange={(event, editor) => {
                const data = editor.getData()
                setArticle(data)
            }}
          />
      </div>
      <div className='admindashboard__form-footer'>
        <button onClick={writeToDatabase}>Publish Article</button>
      </div>
      {alertOpen && 
        <Alert variant="filled" open={alertOpen} onClose={handleAlertClose} severity={alertType}>
          {alertType === "success" ? "Article Published Successfully!" : 
           alertType === "error" ? 
             <>
               <AlertTitle>Error</AlertTitle>
                Some fields are not filled in!
              </>
            : "Error Occured while publishing the article"}
          </Alert>
      }
    </div>
  )
}

export default Form
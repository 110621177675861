import React from "react";
import { Card, Navbar } from "../../components";
import "./dashboard.css";

const Dashboard = () => {
  return (
    <div className="admindashboard__dashboard">
        <Navbar />
        <div className="admindashboard__dashboard-heading">
            <p>DASHBOARD</p>
            <h1 className="heading">Site Overview</h1>
        </div>
        <div className="admindashboard__dashboard-cards section__margin">
            <Card title="ARTICLES" number="342"/>
            <Card title="VISITORS" number="5,498"/>
            <Card title="SUBSCRIBERS" number="172"/>
        </div>
    </div>
  );
};

export default Dashboard;

import React from 'react'
import "./navbar.css"
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  let location = useLocation();

  return (
    <div>
      <div className='admindashboard__navbar'>
        <div className='admindashboard__navbar-logo'>
            <h1>Administrator</h1>
        </div>
        <Link to="/" className={location.pathname === "/" ? "active" : ""}>
        <p>Dashboard</p>
        </Link>
        <Link to="/posts" className={location.pathname === "/posts" ? "active" : ""}>
        <p>Posts</p>
        </Link>
        <Link to="/add-new-post" className={location.pathname === "/add-new-post" ? "active" : ""}>
        <p>Add New Post</p>
        </Link>
        <Link to="/profile" className={location.pathname === "/profile" ? "active" : ""}>
        <p>Profile</p>
        </Link>
      </div>
    </div>
  )
}

export default Navbar

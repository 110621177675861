import React from 'react'
import "./card.css"

const Card = ( { title, number } ) => {
  return (
    <div className='admindashboard__card'>
        <div className='admindashboard__card-body'>
            <p>{title}</p>
            <h1>{number}</h1>
        </div>
    </div>
  )
}

export default Card

import React from 'react'
import "./newpost.css"
import { Navbar, Form } from '../../components'

const Newpost = () => {
  return (
    <div className="admindashboard__newpost">
        <Navbar />
        <div className="admindashboard__newpost-heading">
            <p>ARTICLE POSTS</p>
            <h1 className="heading">Add New Post</h1>
        </div>
        <div className='admindashboard__newpost-form section__margin'>
            <Form />
        </div>
    </div>
  )
}

export default Newpost

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard, Newpost,  } from "../pages/";

const MainRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/add-new-post" element={<Newpost />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;